<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" class="px-0" v-if="passoGerente != null">
                <v-select
                  id="select_aprovador_gerente"
                  name="select_aprovador_gerente"
                  item-text="nome"
                  item-value="id"
                  clearable
                  v-model="idGerente"
                  :rules="[rules.required]"
                  :items="passoGerente.usuarios"
                  :label="`${$tc('label.gerente_comercial', 1)} *`">
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" class="item-lista py-1">
                      <v-list-item-content>
                        <span>{{ item.nome }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" class="px-0"
                v-if="possuiPassoFornecedor"
                v-for="(passoFornecedor, index) in passosFornecedor"
                :key="index">
                <v-select
                  v-if="isPassoFornecedorNegociador(passoFornecedor) || !passoFornecedor.indRepresentacaoConjunto"
                  :id="`select_aprovador_fornecedor-${index}`"
                  :name="`select_aprovador_fornecedor-${index}`"
                  item-text="nome"
                  item-value="id"
                  clearable
                  v-model="passoFornecedor.idFornecedorAprovador"
                  :rules="[rules.required]"
                  :items="passoFornecedor.usuarios"
                  :label="`${$tc('label.tipos_fornecedor.' + passoFornecedor.tipoFornecedor.toLowerCase(), 1)} *`">
                </v-select>
                <v-select
                  v-else
                  v-for="(idFornecedorAprovador, indexForn) in passoFornecedor.idsFornecedoresAprovadores"
                  :key="indexForn"
                  :id="`select_aprovador_fornecedor-${indexForn}`"
                  :name="`select_aprovador_fornecedor-${indexForn}`"
                  item-text="nome"
                  item-value="id"
                  disabled
                  v-model="passoFornecedor.idsFornecedoresAprovadores[indexForn]"
                  :rules="[rules.required]"
                  :items="passoFornecedor.usuarios"
                  :label="`${$tc('label.tipos_fornecedor.' + passoFornecedor.tipoFornecedor.toLowerCase(), 1)} *`">
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary darken-1" @click.native="confirma">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  name: 'PlanejamentoContratoFormUsuarioCondicional',
  data() {
    return {
      showModal: false,
      idGerente: undefined,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    passosFornecedor: {
      type: Array,
      default: [],
    },
    passoGerente: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    possuiPassoFornecedor() {
      return this.passosFornecedor.length > 0;
    },
  },
  methods: {
    open() {
      this.idGerente = undefined;
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    confirma() {
      if (!this.$refs.form.validate()) return;
      const dadosCondicional = {
        passosFornecedor: [],
      };
      if (this.passoGerente != null && this.passoGerente.idPasso) {
        dadosCondicional.idPassoGerente = this.passoGerente.idPasso;
        dadosCondicional.idGerente = this.idGerente;
      }
      this.passosFornecedor.forEach((passo, index) => {
        dadosCondicional.passosFornecedor[index] = {
          idPasso: passo.idPasso,
          tipoFornecedor: passo.tipoFornecedor,
        };

        if (this.isPassoFornecedorNegociador(passo) || !passo.indRepresentacaoConjunto) {
          dadosCondicional.passosFornecedor[index].idsFornecedoresAprovadores = [passo.idFornecedorAprovador];
        } else {
          dadosCondicional.passosFornecedor[index].idsFornecedoresAprovadores = passo.idsFornecedoresAprovadores;
        }
      });
      this.$emit('selecionarUsuarioAprovador', dadosCondicional);
      this.showModal = false;
    },
    isPassoFornecedorNegociador(passoFornecedor) {
      return passoFornecedor.tipoFornecedor === 'FORNECEDOR_NEGOCIADOR';
    },
  },
};
</script>
<style lang="css">
.item-lista:hover{
   background-color: rgb(221, 220, 220);
   opacity: 0,5;
}
</style>
